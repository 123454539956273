///<reference path="../../../../Scripts/typings/jquery/jquery.d.ts"/>
///<reference path="../../../../Scripts/typings/jquery/jquery.mark.d.ts"/>

class PolisVoorwaarden
{
    static voorwaardenFilterChanged(filterElement: Element, voorwaardenContainer: Element)
	{

        const filterText = $(filterElement).val();
        const voorwaarde = $(".category .voorwaarde", voorwaardenContainer);
        const category = $(".category", voorwaardenContainer);

        category.show();
        $(".voorwaarden", $(".category :not('.collapsed')", voorwaardenContainer)).show();

        voorwaarde.unmark().show();

        if (filterText)
		{
            voorwaarde.mark(filterText,
                {
                    acrossElements: true,
                    done()
					{
                        voorwaarde.not(":has(mark)").hide();
                        category.not(":has(mark)").hide();
                    }
                });
        }
    };
};

class OverzichtStyleRenderer
{
    static ValuesRendered()
	{
        const premievergelijkingen = document.querySelectorAll("div.agentpremie_scroll");

        $(premievergelijkingen).each(this.ApplyPremievergelijkingStyling);
    }

    static ApplyPremievergelijkingStyling(_: number, premievergelijkingElement: Element)
	{
        let index = 1;

        $(premievergelijkingElement)
            .find("div.propertypvl")
            .filter(":visible")
            .each(
            (_, propertyElement) =>
			{
                propertyElement.classList.remove("even");
                propertyElement.classList.remove("odd");

                const className = (index++ % 2 === 0) ? "even" : "odd";
                propertyElement.classList.add(className);
            }
        );

        $(premievergelijkingElement)
            .find("input[name='keuze']")
            .filter(":visible")
            .each(
            (_, keuzeElement) => {
                $("div.stars[data-polisid='" + $(keuzeElement).val().replace('-', '') + "'", premievergelijkingElement).each(
                    (_, starsElement) => {
                        starsElement.classList.remove("selected");

                        if ($(keuzeElement).is(":checked")) {
                            starsElement.classList.add("selected");
                        }
                    });
            }
		);

		$(document).ready(() => {
			$('#scroller').css('width', $('#scroller2')[0].scrollWidth - 2 + 'px');
			$('#scroller2').css('max-width', $(window).width() - 20 + 'px');
		});

		$('#scroller div.input span.checkbox').each((_, checkboxElement) =>
			{
			$(checkboxElement).addClass("small");
			});
	}

	static ExpandProductInformatie(workflowId: string)
	{
		$(`div.fw_${workflowId} div.productinformatie`).toggleClass("collapsed");
		$(`div.fw_${workflowId} div.productinformatie div.content`).slideToggle();

		this.ValuesRendered();
	}
}

// TODO: Zorgen dat de compiler dit automatisch doet?
(<any>window)["OverzichtStyleRenderer"] = OverzichtStyleRenderer;
(<any>window)["PolisVoorwaarden"] = PolisVoorwaarden;